import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { useDispatch } from 'react-redux';
import { openDB } from 'idb';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
  Button,
  Snackbar,
  Modal,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import Parse from 'parse';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';
import { fetchConnectorAction } from '../connectors/connector.actions';
export const PushNotificationFollow = ({
  owner,
  from,
  callBack,
  existingUser,
  beacon,
}) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  // const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [email, setEmail] = existingUser?.get('email')
    ? useState(existingUser?.get('email'))
    : useState('');
  const [name, setName] = existingUser?.get('name')
    ? useState(existingUser?.get('name'))
    : useState('');
  const [token, setToken] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [browser, setBrowser] = useState('');
  const [following, setFollowing] = useState(false);
  const [showIosInstructionModal, setShowIosInstructionModal] = useState(false);
  const dispatch = useDispatch();
  let messaging;
  let app;
  const followFieldObject = {};
  // let existingUser = existingUser;
  const userAgent = navigator.userAgent.toLowerCase();
  const isIos =
    /ipad|iphone|ipod/.test(userAgent) &&
    /applewebkit/.test(userAgent) &&
    !window.MSStream;

  const theme = useTheme();

  useEffect(() => {
    const checkIfFollowing = async () => {
      const config = {
        apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      };

      // initialize Firebase messaging
      app = initializeApp(config);
      getAnalytics(app);
      messaging = getMessaging(app);

      try {
        if (existingUser) {
          const params = {
            userId: owner,
            followerId: existingUser.id,
          };
          setToken(existingUser);
          setEmail(existingUser.get('email'));
          const isFollower = await Parse.Cloud.run('isFollower', params);
          setFollowing(isFollower);
        }
        //}
      } catch (error) {
        error;
      }
    };

    checkIfFollowing();

    if (
      userAgent.indexOf('safari') !== -1 &&
      userAgent.indexOf('chrome') === -1 &&
      userAgent.indexOf('crios') === -1
    ) {
      setBrowser('safari');
    } else if (userAgent.indexOf('firefox') !== -1) {
      setBrowser('firefox');
    } else if (
      userAgent.indexOf('chrome') !== -1 ||
      userAgent.indexOf('crios') !== -1
    ) {
      setBrowser('chrome');
    }

    try {
      fetchManifest();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const fetchManifest = async () => {
    try {
      const manifest = await Parse.Cloud.run('generateManifest', {
        userId: owner,
        appUrl: window.location.href,
      });
      await saveManifestToIndexedDB(manifest);
      const manifestUrl = await getManifestUrlFromIndexedDB();

      let link = document.querySelector('link[rel="manifest"]');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'manifest';
        document.head.appendChild(link);
      }
      setIOSMetaTags(manifest);

      // Add a cache-busting query parameter
      link.href = `${manifestUrl}?t=${new Date().getTime()}`;
    } catch (error) {
      console.error('Error generating manifest:', error);
    }
  };

  const saveManifestToIndexedDB = async (manifest) => {
    const db = await openDB('manifestDB', 1, {
      upgrade(db) {
        db.createObjectStore('manifests');
      },
    });
    await db.put('manifests', manifest, 'manifest');
  };

  const getManifestUrlFromIndexedDB = async () => {
    const db = await openDB('manifestDB', 1);
    const manifest = await db.get('manifests', 'manifest');
    const blob = new Blob([JSON.stringify(manifest)], {
      type: 'application/json',
    });
    return URL.createObjectURL(blob);
  };

  const setIOSMetaTags = (manifest) => {
    const { name, icons } = manifest;

    // Update apple-mobile-web-app-title
    let appleTitle = document.querySelector(
      'meta[name="apple-mobile-web-app-title"]'
    );
    if (!appleTitle) {
      appleTitle = document.createElement('meta');
      appleTitle.name = 'apple-mobile-web-app-title';
      document.head.appendChild(appleTitle);
    }
    appleTitle.content = name;

    // Update apple-touch-icon
    let appleIcon = document.querySelector('link[rel="apple-touch-icon"]');
    if (!appleIcon) {
      appleIcon = document.createElement('link');
      appleIcon.rel = 'apple-touch-icon';
      document.head.appendChild(appleIcon);
    }

    // Use the largest icon available for the apple-touch-icon
    if (icons && icons.length > 0) {
      const largestIcon = icons.reduce((prev, current) =>
        parseInt(prev.sizes.split('x')[0], 10) >
        parseInt(current.sizes.split('x')[0], 10)
          ? prev
          : current
      );
      appleIcon.href = largestIcon.src;
    }
  };

  const groupByActionWithValues = (array, additionalObj) => {
    return array.reduce((result, item) => {
      const action = item.action;
      const id = item.id;
      if (additionalObj[id]) {
        if (!result[action]) {
          result[action] = {};
        }
        result[action][id] = additionalObj[id];
      }
      return result;
    }, {});
  };

  const getInstructions = () => {
    switch (browser) {
      case 'safari':
        if (isIos) {
          return (
            <>
              <Typography variant="h6" component="h2">
                Add to Home Screen
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Adding our app to your home screen gives you quick and easy
                access, just like a native app.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                1. Tap the <strong>Share</strong> button{' '}
                <IosShareIcon fontSize="small" /> at the bottom of the screen.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                2. Select <strong>&quot;Add to Home Screen&quot;</strong>.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                3. Follow the prompts to add the app to your home screen.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                4. After adding, open the app from your home screen and tap
                {` ${beacon?.followButtonText}` || ` Follow ${from}`}
                again to start receiving notifications.
              </Typography>
            </>
          );
        } else {
          return 'Notifications are blocked. To enable notifications, go to Settings > Safari > Notifications and turn on Allow Notifications.';
        }
      case 'firefox':
        return 'Notifications are blocked. To enable notifications, click the lock icon in the address bar, click the arrow next to "Connection secure," and then click "More Information." In the "Permissions" section, make sure "Receive Notifications" is set to "Allow."';
      case 'chrome':
        if (isIos) {
          return (
            <>
              <Typography variant="h6" component="h2">
                Add to Home Screen
              </Typography>
              <Typography sx={{ mt: 2 }}>
                Adding our app to your home screen gives you quick and easy
                access, just like a native app.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                1. Tap the <strong>menu icon</strong>{' '}
                <MoreVertIcon fontSize="small" /> in the top right corner.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                2. Select <strong>&quot;Add to Home Screen&quot;</strong>.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                3. Follow the prompts to add the app to your home screen.
              </Typography>
              <Typography sx={{ mt: 2 }}>
                4. After adding, open the app from your home screen and tap
                {` ${beacon?.followButtonText}` || ` Follow ${from}`}
                again to start receiving notifications.
              </Typography>
            </>
          );
        } else {
          return 'Notifications are blocked.  To enable notifications, click the lock or info icon in the address bar, click "Site settings," and then turn on "Notifications."';
        }
      default:
        return 'Please enable notifications to receive updates';
    }
  };

  useEffect(() => {
    if (callBack) {
      callBack(token);
    }
  }, [following]);

  const handleEmailSubmit = async () => {
    setShowEmailModal(false);
    handleFollowClick();
    //await subscribeToPushNotifications(email);
  };

  const handleFollowClick = async () => {
    const registration = await navigator.serviceWorker.ready;
    //await initPushNotifications();
    try {
      if (Notification.permission === 'granted') {
        if (following) {
          await unsubscribeFromPushNotifications(owner);
        } else {
          //setShowEmailModal(true);
          await subscribeToPushNotifications(email, registration);
        }
      } else if (Notification.permission !== 'denied') {
        await Notification.requestPermission();
        handleFollowClick();
      } else if (Notification.permission === 'denied') {
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log('error name ', error.name);
    }
  };

  // const addPromptHomeScreen = async () => {
  //   if (!window.matchMedia('(display-mode: standalone)').matches) {
  //     if (
  //       userAgent.indexOf('safari') !== -1 &&
  //       userAgent.indexOf('chrome') === -1
  //     ) {
  //       promptAddToHomeScreen();
  //     }
  //   }
  // };

  const subscribeToPushNotifications = async (email, registration) => {
    // subscribe to push notifications for the owner
    messaging = getMessaging(app);
    console.log('what is going on subscribe push notification ', registration);

    const retrySubscribe = async (email, attempts = 25, delay = 700) => {
      for (let i = 0; i < attempts; i++) {
        try {
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_WEB_PUSH_KEY,
          });

          if (!existingUser) {
            existingUser = await Parse.Cloud.run('getUserByEmail', {
              email: email,
            });
          }

          if (currentToken) {
            if (existingUser) {
              if (following) {
                existingUser.set('customFields', followFieldObject);
                existingUser.set('webPushId', currentToken);
                //group followButtonFields by action
              } else {
                existingUser.set('webPushId', currentToken);
                existingUser.set('customFields', followFieldObject);
                existingUser.set('name', name);
                existingUser.set('email', email);

                await Parse.Cloud.run('addFollower', {
                  userId: owner,
                  followerId: existingUser.id,
                });
                setFollowing(true);
              }

              await Parse.Cloud.run('updateUser', existingUser.toJSON());
            } else {
              const username = uuidv4();
              const password = uuidv4();
              const user = new Parse.User();
              user.set('username', username);
              user.set('password', password);
              user.set('webPushId', currentToken);
              user.set('name', name);
              user.set('email', email);
              user.set('customFields', followFieldObject);

              const follower = await user.save();
              await Parse.Cloud.run('addFollower', {
                userId: owner,
                followerId: follower.id,
              });
              setFollowing(true);
            }

            if (beacon?.followButtonFields) {
              const actionWithValues = groupByActionWithValues(
                beacon?.followButtonFields,
                followFieldObject
              );

              Object.keys(actionWithValues).forEach((key) => {
                const fieldToGetMethodFrom = beacon?.followButtonFields.find(
                  (field) => field.action == key
                );

                if (fieldToGetMethodFrom) {
                  const requestParams = {};
                  requestParams.path = key;
                  requestParams.method = fieldToGetMethodFrom.method;
                  if (
                    requestParams.method &&
                    requestParams.method.toLowerCase() === 'post'
                  ) {
                    requestParams.body = actionWithValues[key];
                  } else {
                    requestParams.params = actionWithValues[key];
                  }
                  dispatch(fetchConnectorAction(requestParams));
                }
              });
            }
            return; // Exit the retry loop if successful
          }
        } catch (error) {
          if (i < attempts - 1) {
            await new Promise((resolve) => setTimeout(resolve, delay)); // Wait for the specified delay before retrying
          } else {
            return;
          }
        }
      }
    };

    try {
      await retrySubscribe(email);
    } catch (e) {
      console.log('e ', e);
    }
  };

  const unsubscribeFromPushNotifications = async () => {
    // unsubscribe from push notifications for the owner
    try {
      // const currentToken = await getToken(messaging, {
      //   vapidKey: process.env.REACT_APP_WEB_PUSH_KEY,
      // });

      if (existingUser.get('webPushId')) {
        // check if a user with the device token already exists
        // const query = new Parse.Query(Parse.User);
        // query.equalTo('webPushId', existingUser.get('webPushId'));
        // existingUser = await query.first();

        Parse.Cloud.run('removeFollower', {
          userId: owner,
          followerId: existingUser.id,
        })
          .then(() => {
            setFollowing(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const promptAddToHomeScreen = () => {
  //   // check if the app is already installed on the home screen
  //   if (window.navigator.standalone) {
  //     return;
  //   }

  //   // create a banner that prompts the user to add the app to their home screen
  //   const banner = document.createElement('div');
  //   banner.style.position = 'fixed';
  //   banner.style.bottom = '0';
  //   banner.style.left = '0';
  //   banner.style.right = '0';
  //   banner.style.backgroundColor = '#f2f2f2';
  //   banner.style.padding = '16px';
  //   banner.style.display = 'flex';
  //   banner.style.alignItems = 'center';
  //   banner.style.zIndex = '9999';

  //   const icon = document.createElement('img');
  //   icon.src = '/images/lifeTagger_logo.png';
  //   icon.style.width = '48px';
  //   icon.style.height = '48px';
  //   icon.style.marginRight = '16px';

  //   const message = document.createElement('div');
  //   message.textContent =
  //     'Add this app to your home screen for the best experience.';
  //   message.style.flexGrow = '1';

  //   const button = document.createElement('button');
  //   button.textContent = 'Add to Home Screen';
  //   button.style.backgroundColor = '#007aff';
  //   button.style.color = '#fff';
  //   button.style.border = 'none';
  //   button.style.borderRadius = '8px';
  //   button.style.padding = '8px 16px';
  //   button.style.fontWeight = 'bold';
  //   button.style.cursor = 'pointer';

  //   button.addEventListener('click', () => {
  //     // prompt the user to add the app to their home screen
  //     // const meta = document.createElement('meta');
  //     // meta.name = 'apple-mobile-web-app-capable';
  //     // meta.content = 'yes';
  //     // document.head.appendChild(meta);

  //     // const link = document.createElement('link');
  //     // link.rel = 'apple-touch-icon';
  //     // link.href = '/images/lifeTagger_logo.png';
  //     // document.head.appendChild(link);
  //     console.log('this is deffered prompt.. ', deferredPrompt);
  //     if (deferredPrompt) {
  //       // Show the prompt
  //       deferredPrompt.prompt();
  //       // Wait for the user to respond to the prompt
  //       deferredPrompt.userChoice.then((choiceResult) => {
  //         if (choiceResult.outcome === 'accepted') {
  //           console.log('User accepted the A2HS prompt');
  //         } else {
  //           console.log(
  //             'User dismissed the A2HS prompt ',
  //             choiceResult.outcome
  //           );
  //         }
  //         // Clear the saved prompt since it can't be used again
  //         setDeferredPrompt(null);
  //         // Hide the custom prompt
  //       });
  //     }

  //     banner.remove();
  //   });

  //   banner.appendChild(icon);
  //   banner.appendChild(message);
  //   banner.appendChild(button);

  //   document.body.appendChild(banner);
  // };

  return (
    <div>
      {following && (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleFollowClick}
          >
            Unfollow {from}
          </Button>
        </div>
      )}
      {!following &&
        (!isIos ||
          window.navigator.standalone ||
          window.matchMedia('(display-mode: standalone)').matches) && (
          <div>
            <Button
              variant="contained"
              color="text"
              sx={{ backgroundColor: 'white' }}
              onClick={() => setShowEmailModal(true)}
            >
              {beacon?.followButtonText || `Follow ${from}`}
            </Button>
            <Modal
              open={showEmailModal}
              onClose={() => setShowEmailModal(false)}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              sx={{
                position: 'absolute',
                top: '50%',
              }}
            >
              <Box sx={theme.custom.modal}>
                <Grid container direction={'column'}>
                  <Grid item xs={12} alignSelf={'flex-end'}>
                    <Button
                      sx={theme.custom.modalCloseButton}
                      onClick={() => setShowEmailModal(false)}
                    >
                      x
                    </Button>
                  </Grid>
                  <Grid
                    xs={12}
                    sx={theme.custom.modalText}
                    alignSelf={'center'}
                  >
                    Enter your email address to follow {from}
                  </Grid>
                  <Grid xs={12}>
                    {existingUser && existingUser.toJSON().name ? (
                      <TextField
                        id="name"
                        label="Name: "
                        variant={'outlined'}
                        fullWidth
                        disabled
                        value={existingUser.toJSON().name}
                        margin="normal"
                        sx={{ marginBottom: '25px' }}
                        onBlur={(em) => setName(em.target.value)}
                      />
                    ) : (
                      <TextField
                        required
                        id="name"
                        label="Name: "
                        variant={'outlined'}
                        fullWidth
                        margin="normal"
                        sx={{ marginBottom: '25px' }}
                        onBlur={(em) => setName(em.target.value)}
                      />
                    )}
                  </Grid>
                  <Grid xs={12}>
                    {existingUser && existingUser.getEmail() ? (
                      <TextField
                        required
                        id="email"
                        disabled
                        label="Email: "
                        variant={'outlined'}
                        fullWidth
                        value={existingUser.getEmail()}
                        margin="normal"
                        sx={{ marginBottom: '25px' }}
                        onBlur={(em) => setEmail(em.target.value.toLowerCase())}
                      />
                    ) : (
                      <TextField
                        required
                        id="email"
                        label="Email: "
                        variant={'outlined'}
                        fullWidth
                        margin="normal"
                        sx={{ marginBottom: '25px' }}
                        onBlur={(em) => setEmail(em.target.value.toLowerCase())}
                      />
                    )}
                  </Grid>
                  {beacon?.followButtonFields?.map((field, index) => (
                    <Grid xs={12} key={index}>
                      <TextField
                        required
                        id={field.id}
                        name={field.name}
                        label={field.label}
                        variant={'outlined'}
                        fullWidth
                        defaultValue=""
                        margin="normal"
                        sx={{ marginBottom: '25px' }}
                        onBlur={(em) =>
                          (followFieldObject[field.id] = em.target.value)
                        }
                      />
                    </Grid>
                  ))}
                  <Grid item xs={6}>
                    <Button
                      sx={theme.custom.modalAcceptButton}
                      disabled={!email || !name}
                      onClick={handleEmailSubmit}
                    >
                      submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
        )}
      {!following &&
        isIos &&
        !window.navigator.standalone &&
        !window.matchMedia('(display-mode: standalone)').matches && (
          <div>
            <Button
              variant="contained"
              color="text"
              sx={{ backgroundColor: 'white' }}
              onClick={() => setShowIosInstructionModal(true)}
            >
              {beacon?.followButtonText || `Follow ${from}`}
            </Button>
            <Modal
              open={showIosInstructionModal}
              onClose={() => setShowIosInstructionModal(false)}
              aria-labelledby="ios-instruction-modal-title"
              aria-describedby="ios-instruction-modal-description"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  maxWidth: 400,
                  textAlign: 'center',
                }}
              >
                {getInstructions()}
                <Button
                  onClick={() => {
                    setShowIosInstructionModal(false);
                  }}
                  variant="contained"
                  sx={{ mt: 4 }}
                >
                  Got it
                </Button>
              </Box>
            </Modal>
          </div>
        )}
      <Snackbar
        open={showSnackbar}
        message={getInstructions()}
        action={
          <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      />
    </div>
  );
};
